import { useState } from 'react';
import { Formik, Form } from 'formik';
import classnames from 'classnames';
import { ErrorBoundary } from 'react-error-boundary';
import { Button, FormikInput, Typography, FormikCheckbox, Link } from '@webfx/core-web';
import { useLoginV1 } from '../../hooks/useLoginV1';
import { GoogleSignInButton } from '../GoogleSignInButton';
import { Office365SignInButton } from '../Office365SignInButton';

import styles from './SignInForm.module.css';

/**
 *
 * @param root0
 * @param root0.onClickForgot
 */
export function SignInForm({ onClickForgot }) {
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const login = useLoginV1();

  return (
    <div className={classnames(['container', styles.container])}>
      <Typography
        component="h1"
        variant="jumboTitle1"
        className={classnames(['text-center', styles.title])}
      >
        Your Competitive Advantage through Data Intelligence
      </Typography>

      <Typography component="h4" variant="h4" bold className="text-uppercase mb-3">
        Client & Fxer Log In:
      </Typography>

      <div className={`d-flex justify-content-between flex-wrap ${styles.signWithWrapper} mb-4`}>
        <ErrorBoundary FallbackComponent={() => null}>
          <GoogleSignInButton />
        </ErrorBoundary>
        <Office365SignInButton
          clearErrors={() => login.setError(null)}
          isLoading={login.disabled}
        />
      </div>

      <div className={styles.separatorWrapper}>
        <hr />
        <Typography className={styles.separatorText} uppercase bold component="p" variant="p">
          Or
        </Typography>
        <hr />
      </div>

      <Formik {...login.form}>
        {() => (
          <Form>
            <div data-fx-name="loginError">
              <SignInError message={login.error} />
            </div>
            <FormikInput
              data-fx-name="email"
              label="Email Address"
              labelPosition="embed"
              className="mb-3 email-field"
              name="email"
              type="email"
              placeholder="Email Address"
              errorVisible={false}
              icon="email"
              iconOutined
              iconPosition="right"
              variant="lg"
              tabIndex="1"
            />

            <FormikInput
              data-fx-name="password"
              label="Password"
              labelPosition="embed"
              className="mb-3 password-field"
              type={isPasswordVisible ? 'text' : 'password'}
              name="password"
              placeholder="Password"
              errorVisible={false}
              icon="visibility"
              iconPosition="right"
              onIconClick={() => setIsPasswordVisible(!isPasswordVisible)}
              variant="lg"
              tabIndex="2"
            />

            <div className={styles.btnWrap} data-fx-name="submit">
              <Button
                data-fx-name="submit"
                className="submitButton"
                type="submit"
                variant="primary"
                text="Login"
                fullWidth
                uppercase
                jumbo
                isLoading={login.disabled}
                disabled={login.disabled || login.form.isSubmitting}
              />
            </div>
            <div className={styles.optionsWrapper}>
              <div className={styles.rememberLog}>
                <label className="d-flex mb-0 align-items-center">
                  <FormikCheckbox name="remember" />
                  <Typography className="m-0" component="p" variant="p">
                    Keep me logged in
                  </Typography>
                </label>
              </div>
              {window.location.href !== 'signin' ? (
                <Link className="font-14" to="/signin">
                  Login Without Password
                </Link>
              ) : (
                <Button onClick={onClickForgot} variant="link" text="Forgot password?" />
              )}
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}

function SignInError({ message }) {
  if (!message) {
    return null;
  }

  return <div className="alert alert-danger">{message}</div>;
}
